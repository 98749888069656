*, * ::after, * ::before {
  box-sizing: border-box;
  margin: 0;
}

body {
  background: slategray;
  height: 100vh;
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

h1 {
  font-size: 4rem;
}

button {
  border: #888 solid 1px;
  padding: .2rem;
}

.main-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.main-container {
  width: clamp(15rem,80vw, 30rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  background: whitesmoke;
  padding: 1rem;
  margin-bottom: 3rem;
}

dialog {  
  width: clamp(10rem,70vw, 25rem);
  margin: auto;
  padding: 2rem;
}

.delete-confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.delete-confirmation p {
  width: 80%;
  text-align: center;
}

.confirmation-buttons {
  width: 80%;
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.confirmation-buttons button {

  flex-basis: 50%;
  flex-grow: 1;
}

.header {
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}


.results-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  margin-top: .5rem;
}


/* SEARCH CONTROLS */

.search-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 70%;
}


.search-form {
display:flex;
flex-direction: column;
align-items: center;
gap: .5rem;
}

.search-input {
  display: flex;

  flex-direction: column;
  align-items: center;
  gap: .5rem;
  width: 100%;
}

@media screen and (min-width: 360px) {
  .search-input {
    flex-direction: row;

  }
  
}

.search-input button {
  min-width: 4rem;
}

.search-field {
  flex: 1;
}

/* TABLES */

table {
  display: flex;
  flex-direction: column;
  width: 100%;
}

tbody {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 .5rem; 
  border: solid 1px black;
}

tbody :last-child {
  border: 0;
}

tbody :last-child :last-child {
  word-break: break-all; 
}

tr {
  display: flex;

  border-bottom: solid 1px black;
  padding: 0.5rem;
}

tr:first-of-type {
  justify-content: space-between;
  padding-right: 0;

}

tr :first-child {
  flex-basis: 7rem;
  flex-shrink: 0;
}



.table-buttons {
  display: flex;
  gap: .5rem;
  flex-basis: 50%;
  flex-wrap: wrap;
}

@media screen and (min-width: 360px) {
  .table-buttons {
    flex-wrap: nowrap;
  }
}



.table-buttons > button {
  flex-grow: 1;
  flex-basis: 50%;
  border: #888 solid 1px;
}


/* CREATE/EDIT MODAL */

.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  opacity: 10%;
}

.modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  width: clamp(15rem,80vw, 30rem);
  padding: 2rem;
  padding-bottom: 1rem;
  gap: 1rem;
  box-shadow: 0 0 10px 5px #555;

}

.edit-form {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  min-width: 7rem;
}

.field-wrapper {
  display: flex;
  flex-direction: column;
}

.field-wrapper :first-child{
  margin-bottom: .3rem;
}

.edit-form-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem; 
}

@media screen and (min-width: 300px) {
  .edit-form-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    margin-top: 1rem;
  } 
}

.edit-form-buttons button {
  flex-shrink: 1;
  width:7rem;
}

.top-btn-cont {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 2rem;
}

.top-btn-cont button {
  width: 7rem;
}

.field-error {
  font-size: .75rem;
  color: red;
  height: 1rem;  
}

.confirmation-text {
  height: 1.5rem;
  color: green;
}


